<template>
  <div class="music-container">
    <!-- banner -->
    <div class="banner">
      <div class="banner-container">
        <img src="@/assets/image/picture_bg.png" alt="">
      </div>
      <div class="crumb-wapper container_media">
        <vf-breadcrumb :data="breadData" type="banner"></vf-breadcrumb>
      </div>
      <div class="search">
        <div class="centerShow">
          <h3>版权音乐，正品保障！</h3>
          <!-- 搜索框 -->
          <Search class="search-input" mode="label_page" placeholder="输入音乐/标签名称，找音乐" @search="handleSearch" />
          <!-- 热搜标签 -->
          <div class="hot-tag-box">
            <Tag class="hot-tag-box-item" v-for="(item, index) in hotSearch" :key="index" mode="text"
              @onTag="handleHotTagClick(item)">&nbsp;&nbsp;{{ item.name }}&nbsp;&nbsp;</Tag>
          </div>
        </div>
      </div>
    </div>
    <TagsFilte ref="tagsRef" :pager="pageQuery" @getList="UpdateList" @pageChange="getList"></TagsFilte>
    <!-- 音乐列表 -->
    <music-list :listLoading="listLoading" :list="list"></music-list>
    <!-- 分页器 -->
    <div class="vf-music-pagination" v-if="JSON.stringify(pageQuery) !== '{}'">
      <Pagination v-if="pageQuery.total > 0" :hiddenPage="true" position="center" :limit.sync="pageQuery.limit"
        :page.sync="pageQuery.page" @pagination="pagination" :total="pageQuery.total" />
    </div>
  </div>
</template>

<script>
import {
  fetchMusicHotSearch,
  fetchMusicList
} from '@/api/music'
import Search from '@/components/Search'
import Tag from '@/components/Tag'
// import MusicList from '@/components/MusicList'
import Buttons from '@/layout/mixins/Buttons'
import { mapGetters } from 'vuex'
import { send_eventApi } from '@/utils/common.js'
import MusicList from './components/MusicList.vue'
import TagsFilte from './components/TagsFilter.vue'
import Pagination from '@/components/Pagination'
import VfBreadcrumb from '@/components/VfBreadcrumb.vue'

export default {
  name: 'Music',
  components: { Search, Tag, MusicList, TagsFilte, Pagination, VfBreadcrumb },
  data() {
    return {
      breadData: [
        {breadcrumbName: 'PlayList', path: '/'},
        {breadcrumbName: '音乐'}
      ],
      hotSearch: [], // 热搜词集合
      list: [], // 列表数据
      listLoading: false,
      pageQuery: {
        // 分页器相关
        total: 0, // 总条数
        page: 1, // 页码
        limit: 10 // 每页展示条数
      },
      tagParams: {}
    }
  },
  watch: {

  },
  // 如果从视频详情返回的 则不清空已选标签 否则清空
  beforeRouteEnter(to, from, next) {
    console.log(from)
    if (from.name == 'musiceDetail' || from.name ==  'musiceDownDetail') {
      next()
    } else {
      next(vm => {
        vm.pageQuery.page = 1;
        vm.$refs.tagsRef.cleanTagSearch()
        vm.tagParams = {}
        vm.getList()
      })
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    })
  },
  created() {
    this.getHot()
    this.getList()
  },
  mixins: [Buttons],
  methods: {
    /**
     * 获取音乐热搜词
     */
    async getHot() {
      try {
        const params = {
          type: 2
        }
        const { data } = await fetchMusicHotSearch(params)
        this.hotSearch = data
      } catch (e) {
        console.log(e)
      }
    },
    // 筛选项改变更新列表
    UpdateList(params){
      this.tagParams = params
      this.pageQuery.page = 1
      this.getList()
    },
    /**
     * 获取音乐列表
     * @param {Array} tagId 按标签id搜索
     */
    async getList() {
      this.listLoading = true
      try {
        const { data } = await fetchMusicList({
          ...this.tagParams,
          page: this.pageQuery.page,
          page_size: this.pageQuery.limit,
          more: 1
        })
        this.pageQuery.total = data.total
        this.list = data.list
        this.listLoading = false

      } catch (e) {
        console.log(e)
        this.listLoading = false
      }
    },

    // 点击热搜标签
    handleHotTagClick(item) {
      const obj = {
        label_name: item.name,
        user_name: this.userInfo ? this.userInfo.name : '',
        user_id: this.userInfo ? this.userInfo.user_id : ''
      }
      send_eventApi('e_slt', obj)
      this.$router.push({
        path: '/search-result',
        query: {
          type: 2,
          keyword_name: item.name
        }
      })
    },
    // 发送埋点事件
    sendApi(label_name) {
      const obj = {
        label_name: label_name,
        user_name: this.userInfo ? this.userInfo.name : '',
        user_id: this.userInfo ? this.userInfo.user_id : ''
      }
      send_eventApi('e_slt', obj)
    },
    pagination(e) {
      this.pageQuery.page = e.page
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
      this.getList()
    },
    /**
     * 切换页码
     * @param e.page 页码
     */
    handlePageChange(v) {
      console.log(v,'vvv');
      // window.scroll({ top: 300, left: 0, behavior: 'smooth' });
      this.pageQuery.page = v
      this.$set(this.pageQuery,'page',v)
    }
  }
}
</script>

<style lang="scss" scoped>
.vf-music-pagination {
  margin-top: 60px;
  /deep/.el-pagination {

    .btn-quicknext + .number  {
      display: none;
    }

}
}
.music-container {
  min-height: 100vh;
  min-width: 1000px;
  margin-bottom: 70px;

  .banner {
    width: 100%;
    position: relative;

    .banner-container {
      width: 100%;
      height: calc(270px + 60px);
      padding-top: 60px;
      box-sizing: border-box;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .crumb-wapper {
      width: 100%;
      position: absolute;
      top: 84px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
    }
    .search {
      position: absolute;
      left: 50%;
      top: 122px;
      transform: translateX(-50%);
      h3 {
        text-align: center;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 50px;
        text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);
        margin: 0 0 24px;
      }

      /deep/.search-container {
        @include size(786px, 56px);

        .input {
          border: none;
          background: rgba(255, 255, 255, 0.8);

          input::-webkit-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          input::-moz-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          input::-ms-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }
        }

        .btn {
          font-size: 18px;
        }
      }
    }

    .hot-tag-box {
      // @include center-middle-flex;
      width: 637px;
      overflow: hidden; // 溢出隐藏
      display: -webkit-box; //作为弹性盒伸缩模型
      -webkit-box-orient: vertical; // 从上到下垂直排列
      -webkit-line-clamp: 2; //  显示的行数
      margin: 12px auto 0px;
      line-height: 20px;

      &-item {
        @include font-normal($size: 14px, $color: rgba(255, 255, 255, 0.8));
      }

      &-item:hover {
        color: $theme-color;
      }
    }
  }
}
</style>
