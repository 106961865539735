<template>
  <div class="tags-container">
    <div style="border-bottom: 1px solid #E8E8E8;">
      <div class="first-label container_media">
        <div @click="chooseFirstTag({label_id: -1})">
          <img :src="require(`@/assets/svg/tag/${firstActiveKey == -1 ? 'all_select_icon' : 'all_icon'}.svg`)" alt="">
          <span :style="{ color: `${firstActiveKey == -1 ? '#E52A0D' : '#666666'}`}">全部</span>
        </div>
        <div v-for="tag in tagsList" :key="tag.label_id" @click="chooseFirstTag(tag)">
          <img :src="require(`@/assets/svg/tag/${firstActiveKey == tag.label_id ? tag.active_icon : tag.default_icon}.svg`)" alt="">
          <span :style="{ color: `${firstActiveKey == tag.label_id ? '#E52A0D' : '#666666'}`}">{{tag.label_name}}</span>
        </div>
      </div>
    </div>
    <div class="others">
      <div class="container_media">
        <div class="second-label" v-if="firstActiveKey != -1">
          <div class="title">{{firstActiveTag.label_name}}：</div>
          <div class="tags-wrap">
            <span v-for="sonTag in firstActiveTag.son" :key="`sonTag_${sonTag.label_id}`" @click="chooseSecondTag(sonTag)" :class="{'active_label': chooseTagList.includes(sonTag) || secondActiveKey.label_id == sonTag.label_id }">{{sonTag.label_name}}</span>
          </div>
        </div>
        <div class="third-label" v-if="thirdTagsList.length">
          <!-- <div class="s-title">{{secondActiveKey.label_name}}：</div> -->
          <!-- <div v-for="tTag in thirdTagsList" :key="`tTag${tTag.label_id}`" @click="chooseThirdTag(tTag)" :class="{'active_label': chooseTagList.includes(tTag)}">{{tTag.label_name}}</div> -->
          <div class="tags-wrap">
            <span v-for="tTag in thirdTagsList" :key="`tTag${tTag.label_id}`" @click="chooseThirdTag(tTag)" :class="{'t_active_label': chooseTagList.includes(tTag)}">{{tTag.label_name}}</span>
          </div>
        </div>
        <div class="time">
          <div class="title">时长：</div>
          <div v-for="time in timeList" :key="`time_${time.value}`" @click="chooseTime(time.value)">
            <strong v-if="timeChooseValue == time.value"><b></b></strong>
            <i v-else></i>
            {{time.name}}
          </div>
        </div>
        <div class="speed_all">
          <div class="title">速度：</div>
          <div class="speed">
            <div v-for="speed in speedList" :key="`speed_${speed.value}`" @click="chooseSpeed(speed.value)">
              <strong v-if="speedChooseValue == speed.value"><b></b></strong>
              <i v-else></i>
              {{speed.name}}
            </div>
          </div>
          <em></em>
          <div class="sort">
            <div class="title">排序：</div>
            <div v-for="sort in orderList" :key="`sort_${sort.value}`" @click="chooseSort(sort.value)" :class="{'active_label': sortChooseValue == sort.value}">{{sort.label}}</div>
          </div>
          <div class="tag-pagina">
            <i class="pagina_l el-icon-arrow-left" :class="{ 'active': lpage }" @click="prevPage"></i>
            <span class="pager">
              <i class="page">{{ pager.page }}</i>
              <i class="pagesize">/...</i>
            </span>
            <i class="pagina_r el-icon-arrow-right" :class="{ 'active': rpage }" @click="nextPage"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- 标签选中结果 -->
    <div v-if="chooseTagList.length || timeChooseValue || speedChooseValue" class="selected-tag-box container_media">
      <span class="title"><i>音乐列表</i><em>|</em> 已选标签：</span>
      <el-tag v-for="item in chooseTagList" :key="`check_${item.label_id}`" size="small" closable
        @close="handleCloseTag(item)">{{ item.label_name }}</el-tag>
      <el-tag v-if="timeChooseValue" size="small" closable @close="timeChooseValue=''">{{ toLabelName('timeChooseValue','timeList') }}</el-tag>
      <el-tag v-if="speedChooseValue" size="small" closable @close="speedChooseValue=''">{{ toLabelName('speedChooseValue','speedList')}}</el-tag>
      <span class="clean" @click="cleanTagSearch">全部清除</span>
    </div>
  </div>
</template>

<script>
import { fetchMusicTags } from '@/api/music'
import { orderList } from '@/config'

export default {
  name: 'Tags',
  data() {
    return {
      tagsList: [],
      firstActiveTag: {}, // 被选择的一级标签对象 firstActiveTag.son为被选中的二级列表
      firstActiveKey: -1, // 标签列表一级选中下标 默认显示全部
      secondActiveKey: {}, // 包含三级标签的二级标签被选中
      chooseTagList: [], // 被选中的二级标签
      thirdTagsList: [], // 被选中的三级列表 待补全逻辑
      timeChooseValue: '',
      speedChooseValue: '',
      sortChooseValue: '',
      orderList,
      lpage: false,
      rpage: false,
      timeList: [{
        name: '全部',
        value: '',
      }, {
        name: '小于30秒',
        value: 1,
      }, {
        name: '30秒-1分钟',
        value: 2,
      }, {
        name: '1分钟-2分钟',
        value: 3,
      }, {
        name: '2分钟-3分钟',
        value: 4,
      }, {
        name: '3分钟以上',
        value: 5,
      }],
      speedList: [{
        name: '全部',
        value: '',
      }, {
        name: '慢（BPM 0～60）',
        value: 1,
      }, {
        name: '中（BPM 60～100）',
        value: 2,
      }, {
        name: '快（BPM 100以上）',
        value: 3,
      }]
    }
  },
  props: {
    pager: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    pages() {
      return Math.ceil(this.pager.total / this.pager.limit);
    }
  },
  created(){
    this.getTagList()
  },
  methods: {
    chooseFirstTag(tag){
      if(this.firstActiveTag == tag && tag.label_id == -1) return
      // 再次点击除全部外的一级标签，取消选中状态
      if(this.firstActiveTag == tag) {
        this.firstActiveTag = {label_id: -1}
        this.firstActiveKey = -1
      }else {
        this.firstActiveTag = tag
        this.firstActiveKey = tag.label_id
      }
      if(tag.label_id == -1){
        this.chooseTagList = []
      }
      this.thirdTagsList = []
      this.secondActiveKey = {}
    },

    chooseSecondTag(tag){
      // 若有三级标签，展示三级标签选项，否则记录所选标签
      if(tag.son){
        if(tag == this.secondActiveKey){
          this.thirdTagsList = []
          this.secondActiveKey = {}
        }else {
          this.thirdTagsList = tag.son
          this.secondActiveKey = tag
        }
      }else{
        if(this.chooseTagList.includes(tag)){
          const index = this.chooseTagList.findIndex((i) => i.label_id === tag.label_id)
          this.chooseTagList.splice(index, 1)
        }else {
          this.chooseTagList.push(tag)
        }
        this.thirdTagsList = []
        this.secondActiveKey = {}
        this.handleGet()
      }
    },
    chooseThirdTag(tag){
      if(this.chooseTagList.includes(tag)) {
        const index = this.chooseTagList.findIndex((i) => i.label_id === tag.label_id)
        this.chooseTagList.splice(index, 1)
      }else{
        this.chooseTagList.push(tag)
      }
      this.handleGet()
    },
    chooseTime(v){
      if(this.timeChooseValue == v) return
      this.timeChooseValue = v
      this.handleGet()
    },
    chooseSpeed(v){
      if(this.speedChooseValue == v) return
      this.speedChooseValue = v
      this.handleGet()
    },
    chooseSort(v){
      if(this.sortChooseValue == v) return
      this.sortChooseValue = v
      this.handleGet()
    },
    prevPage() {
      if (this.pager.page < 2) return;
      this.pager.page--;
      this.$emit('pageChange')
      this.lpage = true;
      this.rpage = false;
    },
    nextPage() {
      console.log(this.pager, this.pages);
      if (this.pager.page < this.pages) {
        this.pager.page++;
        this.$emit('pageChange')
        this.lpage = false;
        this.rpage = true;
      }
    },
    toLabelName(key,listKey){
      const index = this[listKey].findIndex((i) => i.value === this[key])
      return this[listKey][index].name
    },
    // 清除单个标签
    handleCloseTag(item) {
      const index = this.chooseTagList.findIndex((i) => i.label_id === item.label_id)
      this.chooseTagList.splice(index, 1)
      this.handleGet()
    },
    // 全部清除标签
    cleanTagSearch() {
      this.timeChooseValue = ''
      this.speedChooseValue = ''
      this.chooseTagList = []
      this.thirdTagsList = []
      this.secondActiveKey = {}
      this.firstActiveKey = -1
      this.handleGet()
    },
    handleGet(){
      this.$emit('getList',{
        bpm: this.speedChooseValue,
        duration: this.timeChooseValue,
        order: this.sortChooseValue,
        label_id: this.chooseTagList.map(i=>i.label_id).join(',')
      })
    },
    async getTagList() {
      try {
        const { data } = await fetchMusicTags()
        this.tagsList = data.map(e => {
          if(e.label_name == '场景') {
            e.active_icon = 'scene_select_icon'
            e.default_icon = 'scene_icon'
          }
          if(e.label_name == '情绪') {
            e.active_icon = 'feel_select_icon'
            e.default_icon = 'feel_icon'
          }
          if(e.label_name == '风格') {
            e.active_icon = 'style_select_icon'
            e.default_icon = 'style_icon'
          }

          if(e.label_name == '配器') {
            e.active_icon = 'tool_select_icon'
            e.default_icon = 'tool_icon'
          }
          return e
        })
        // this.tagsList.push({
        //   label_name : '全部',
        //   active_icon : 'all_select_icon.svg',
        //   default_icon : 'all_icon.svg'
        // })
        console.log(data,'tags');
        // console.log(this.tags.list)
        // this.updateTagListStatus(this.tags.list)
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.tags-container{
  width: 100%;
  margin-bottom: 10px;
  color: #666;
  font-size: 14px;
}
.first-label {
  height: 56px;
  display: flex;
  align-items: center;
  div{
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 30px;
    span{
      font-weight: 500;
      margin-left: 6px;
      &:hover{
        color: #E52A0D!important;
      }
    }
  }
}
.others{
  background: #FFFFFF;
  box-shadow: 0px 5px 9px 0px rgba(0,0,0,0.1);
  padding: 8px 0 14px 0;
  .speed_all {
    display: flex;
    align-items: center;
    position: relative;
    em{
      width: 1px;
      height: 14px;
      background: #D8D8D8;
      margin: 0 30px;
    }
  }
  .title {
    width: 46px;
    height: 24px;
    line-height: 24px;
  }
  .time, .speed_all {
    padding: 8px 0;
  }
  .time, .speed {
    display: flex;
    div:not(.title){
      cursor: pointer;
      height: 24px;
      line-height: 24px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      position: relative;

      &:hover {
        color: #E52A0D;
      }
      &::before{
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        // border-radius: 50%;
        // border: 1px solid #ccc;
        margin-right: 10px;
      }
      i{
        position: absolute;
        top: 5px;
        left: 10px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #ccc;
        margin-right: 10px;
      }
      strong{
        position: absolute;
        top: 5px;
        left: 10px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #E52A0D;
        margin-right: 10px;
        b {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 4px;
          height: 4px;
          background: #E52A0D;
          border-radius: 50%;
        }
      }
    }
  }
  .sort{
    display: flex;
  }
  .sort, .tags-wrap {
    // display: flex;
    div:not(.title), span{
      cursor: pointer;
      height: 24px;
      line-height: 24px;
      padding: 0 10px;
      background: #fff;
      border-radius: 4px;
      margin-right: 10px;
      &:hover{
        background: #FDF4F3;
      }
    }
  }
  .tag-pagina {
    @include center-middle-flex($justify: flex-start);
    position: absolute;
    right: 0;
    i {
      font-style: normal;
      display: inline-block;
    }

    .pagina_l,
    .pagina_r {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    .pager {
      margin: 0 15px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
    }

    .page {
      color: #E52A0D;
    }

    .active {
      border-color: #E52A0D;
      color: #E52A0D;
    }
  }
}
.selected-tag-box {
  padding-top: 36px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  margin-bottom: 36px;

  .title {
    @include font-normal($size: 14px, $color: rgba(0, 0, 0, .5));
    i {
      font-style: normal;
      font-size: 18px;
      color: rgba(0, 0, 0, .75);
      font-weight: 600;
    }
    em{
      font-style: normal;
      margin: 0 8px;
    }
  }

  .el-tag {
    border-radius: 12px;
    margin-left: 12px;
    font-size: 14px;
    cursor: pointer;
    padding: 0 12px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border: 1px solid rgba(0,0,0,0.85);

    /deep/ .el-tag__close {
      font-size: 14px;
      right: -2px;
      color: rgba(0, 0, 0, 1);
    }

    /deep/ .el-tag__close:hover {
      background: none;
      color: rgba(0, 0, 0, 1);
    }
  }

  .clean {
    @include font-normal($size: 12px, $color: #4d4d4f);
    line-height: 17px;
    margin-left: 12px;
    cursor: pointer;
  }
}
.second-label {
  display: flex;
  .title{
    margin-top: 8px;
  }
  .s-title{
    min-width: 50px;
    margin-top: 8px;
    color: #E52A0D;
  }
  .tags-wrap{
    flex: 1;
    span{
      margin-top: 8px;
      margin-bottom: 6px;
    }
  }
}
.third-label {
  padding: 10px 20px 4px;
  background: #FAFAFA;
  border-radius: 4px;
  margin: 4px 0 4px 30px;
  .tags-wrap {
    span {
      margin-bottom: 6px;
      background: transparent;
      &:hover{
        background: transparent;
      }
    }
  }
}
.active_label{
  background: #FDF4F3!important;
  color: #E52A0D;
}
.t_active_label {
  color: #E52A0D;
}
</style>
