<template>
  <div class="music-wrap" v-loading="listLoading">
    <template v-if="list.length > 0">
      <div class="music-card-wrap" v-for="item in list" :key="item.music_id">
        <div class="music-card" :class="{'container_media': isMidia}">
          <div class="music-img">
            <div class="header-img" @click="handlePlayerMusic({ ...item, type: 2, labels: item.label })">
              <img class="img" :src="item.logo" alt="">
              <div class="audio-status">
                <img v-if="playing && item.music_id == musicInfo.music_id" src="~@/assets/svg/operation/play_icon.svg"/>
                <img v-else src="~@/assets/svg/operation/pause_icon.svg"/>
              </div>
            </div>
            <div class="info">
              <p class="name" @click="handlePlayerMusic({ ...item, type: 2, labels: item.label })">{{ item.name }}</p>

              <p class="music-labels">{{ item.label?.map((i) => i.name).join('，') }}</p>
            </div>
          </div>
          <div class="music-wave">
            <WaveImage
                :music="item"
                @waveProgress="(pos)=>handleSetProgress(pos, item)"
              ></WaveImage>
          </div>
          <div class="music-op">
            <p class="music-time">{{ (item.time || item.duration) | formatTimeBySecond }}</p>
            <p class="bpm">BPM：{{ item.bpm }}</p>
            <el-tooltip v-if="isSimilar" effect="dark" content="相似音乐" placement="top">
              <router-link target="_blank" :to="`/similar/${item.music_id}`">
               <!-- @click="handleGoSimilar(item.music_id)" -->
                <img src="@/assets/svg/operation/similar_icon.svg" alt="">
              </router-link>
            </el-tooltip>
            <el-tooltip effect="dark" content="收藏音乐" placement="top">
              <img :src="require(`@/assets/svg/operation/${item.is_collect == 0 ? 'collect' : 'collected'}.svg`)" alt=""
              @click="handleMusicCollect(item)">
            </el-tooltip>
            <el-tooltip effect="dark" content="分享音乐" placement="top">
              <img style="margin-right: 40px;" :src="require(`@/assets/svg/operation/share.svg`)" alt=""
              @click="handleShare('', item.object_id, item.type || 2, item.name)">
              <!-- @click="handleShare('', item.object_id, item.type || 2, item.name)" -->
            </el-tooltip>
            <!-- <img :src="require(`@/assets/svg/operation/downLoad.svg`)" alt="" @click="handleDownload(item)">
            <img :src="require(`@/assets/svg/operation/cart.svg`)" alt="" @click="handleAddGoodsToCart(item, 2)"> -->
            <el-tooltip effect="dark" content="单首购买" placement="top">
              <span class="buybtn" @click="handleBuyToInfo(item)">购买</span>
            </el-tooltip>
            <el-tooltip effect="dark" content="使用会员下载高清素材" placement="top">
              <span class="down" @click="handleDown(item)"><img :src="require(`@/assets/svg/operation/down_detail_icon.svg`)" alt="">下载</span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </template>
    <el-empty v-else />
  </div>
</template>

<script>
import Buttons from '@/layout/mixins/Buttons';
import WaveImage from '@/components/WaveImage'
import {mapGetters, mapState, mapActions} from 'vuex'
import { getToken } from "@/utils/author";
import { copyInfo } from '@/utils/utils'

export default {
  components: {
    WaveImage
  },
  props: {
    listLoading: {
      type: Boolean,
      default: false
    },
    isSimilar: {
      type: Boolean,
      default: true
    },
    list: {
      type: Array,
      default: () => []
    },
    position: {
      type: String,
      default: 'center'
    },
    isMidia: {
      type: Boolean,
      default: true
    }
  },
  mixins: [Buttons],
  computed: {
    ...mapGetters(['allLabelList','hasMember']),
    ...mapState('music', [
      'isPlaying',
      'playingMusic',
    ]),
    playing() {
      return !!this.$store.state.music.isPlaying
    },
    musicInfo() {
      return this.$store.state.music.tmpMusicInfo
    },
  },
  methods: {
    ...mapActions('music', [
      'setProgress',
    ]),
    handleShareDetail(id){
      let shareUrl = `${window.location.origin}/music-detail/${id}`
        copyInfo(shareUrl)
        this.$message.success('已复制该链接到剪切板，快去分享吧！')
    },
    // 处理点击波形图初始播放
    handleSetProgress(pos, item){
      if(this.playingMusic.music_id && item.music_id === this.playingMusic.music_id) {
        this.setProgress(pos * this.playingMusic.duration / 100);
      }else {
        this.handlePlayerMusic({ ...item, type: 2, labels: item.label })
      }
    },
    handleBuyToInfo(item) {
      console.log(item,'item');
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      this.$router.push(`/music-detail/${item.music_id}`);

    },
    handleDown(item){
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      if (!this.hasMember) {
        this.$router.push(`/music-down-detail/${item.music_id}`)
        return
      }
      this.handleDownload(item,'single')
    },
    pagination() {
      this.$emit('pagination')
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    },
    handleGoSimilar(id) {
      this.$router.push(`/similar/${id}`)
    },
    async handleMusicCollect(item) {
      if (this.musicInfo && this.musicInfo.object_id) {
        if (item.object_id === this.musicInfo.object_id) {
          this.$message.warning('正在播放的音乐请在播放器中操作')
          return
        }
      }
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      item.is_collect = item.is_collect === 0 ? 1 : 0
      await this.handleCollect(item.object_id, 2, {
        ...item,
        type: 2,
        labels: item.label
      }, false)
    },
  }
}
</script>

<style lang="scss" scoped>
.music-wrap {
  .music-card-wrap {
    padding: 15px 0px;
    &:hover {
      background: #FAFAFA;
    }
  }
  .music-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    .music-img {
      display: flex;
      align-items: center;
      cursor: pointer;
      .header-img{
        width: 70px;
        height: 70px;
        position: relative;
        .img {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.2);
        }
        .audio-status {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, .2);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .info {
        margin-left: 12px;

        .name {
          cursor: pointer;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
          margin-bottom: 8px;
          max-width: 12vw;
          min-width: 12vw;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .music-labels,
    .music-time,
    .bpm {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 17px;
    }
    .bpm {
      min-width: 60px;
      margin-right: 24px;
    }
    .music-time {
      min-width: 36px;
      margin-right: 26px;
    }

    .music-labels {
      cursor: default;
      width: 15vw;
      max-width: 230px;
      max-width: 230px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .music-wave{
      flex: 1;
      min-width: 140px;
      max-width: 500px;
      margin: 0 24px;
      height: 36px;
    }

    .music-op {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        cursor: pointer;
        margin-right: 14px;

        &:last-child {
          margin-right: 0;
        }
      }
      a {
        margin-right: 14px;
        display: flex;
        justify-content: center;
      }
      span {
        cursor: pointer;
        height: 32px;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
      }
      span.buybtn {
        width: 60px;
        line-height: 32px;
        color: #E52A0D;
        border: 1px solid #E52A0D;
      }
      span.down{
        width: 80px;
        background: #E52A0D;
        color: #FFFFFF;
        text-align: center;
        margin-left: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }
    }
  }

  .vf-music-pagination {
    width: 100%;
    margin-top: 50px;
  }
}
</style>
